import {createStore} from 'redux';
import rootReducer from './reducers.js'
//redux-thunk?

/*
let initialState = {

};*/

let store = createStore(
    rootReducer,
    //initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;